import React from "react";
import { FiArrowLeftCircle } from "react-icons/fi";

type BackButtonProps = {
  onClick: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return <FiArrowLeftCircle size={36} onClick={onClick} />;
};

export default BackButton;
