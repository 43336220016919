export const guidelines = [
  {
    title: "No Harassment or Discrimination",
    points: [
      "Treat others with respect and kindness. Harassment, discrimination, and hate speech are not tolerated.",
      "Report any instances of inappropriate behavior or language.",
    ],
  },
  {
    title: "Authenticity",
    points: [
      "Use genuine and recent photos.",
      "Be honest about your intentions and personal details.",
    ],
  },
  {
    title: "Safety First",
    points: [
      "Prioritize safety when meeting in person.",
      "Inform a friend about your plans and meet in public places.",
    ],
  },
  {
    title: "No Offensive Content",
    points: [
      "Avoid sharing content that is explicit, offensive, or violates community standards.",
      "Respect cultural sensitivities and diversity.",
    ],
  },
  {
    title: "Privacy & Anonymity",
    points: [
      "Respect the privacy of others–do not share personal information or real-world identifiers.",
    ],
  },
  {
    title: "Blocking and Reporting",
    points: [
      "Utilize the blocking feature for users who violate guidelines.",
      "Report any suspicious or inappropriate behavior to the app administrators.",
    ],
  },
  {
    title: "No Spam or Solicitation",
    points: [
      "Refrain from promoting products, services, or soliciting funds on the platform.",
      "Focus on genuine connections rather than self-promotion.",
    ],
  },
  {
    title: "Community Etiquette",
    points: [
      "Be courteous and considerate in your interactions.",
      "Understand and adhere to the app's specific community guidelines.",
    ],
  },
  {
    title: "Inclusive Environment",
    points: [
      "Embrace diversity and foster an inclusive environment.",
      "Avoid discriminatory language and behavior.",
    ],
  },
  {
    title: "Moderation and Enforcement",
    points: [
      "App administrators have the right to moderate and enforce these guidelines.",
      "Violation may result in warnings, suspensions, or account termination.",
    ],
  },
];
