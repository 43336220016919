import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./pages/About";
import Guidelines from "./pages/Guidelines";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/missed-connections",
    // exact: true,
    element: <About />,
  },
  {
    path: "/privacy-policy",
    // exact: true,
    element: <Privacy />,
  },
  {
    path: "/terms-and-conditions",
    // exact: true,
    element: <Terms />,
  },
  {
    path: "/user-guidelines",
    // exact: true,
    element: <Guidelines />,
  },
]);

function App() {
  return <RouterProvider router={routes} />;
}

export default App;
