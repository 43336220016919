import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import "./about.css";

const About = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  return (
    <div className="About">
      <div className="About-header">
        <BackButton onClick={goBack} />
        <h2>About</h2>
      </div>
      <div className="About-content">
        <p>
          "Winkd" is your go-to mobile app for rekindling missed connections and
          turning fleeting encounters into lasting connections. Inspired by the
          classic charm of Craigslist's "Missed Connections" and the nostalgic
          allure of newspaper personal ads, Winkd brings a modern twist to
          serendipitous encounters.
        </p>
        <p>
          Have you ever locked eyes with someone in a coffee shop, shared a
          smile on the train, or felt a connection at a crowded event, only to
          part ways without exchanging contact information? Winkd is here to
          give those missed moments a second chance.
        </p>
        <p>
          With Winkd, users can post location-based personal advertisements,
          describing the encounter and expressing their desire to reconnect with
          that special someone. The app's intuitive design allows users to
          browse through missed connections in their vicinity, creating a sense
          of community and shared experiences.
        </p>
        <h3>Key Features:</h3>
        <ul>
          <li>
            Location-Based Connections: Winkd utilizes geolocation to connect
            users with missed connections in their immediate area, increasing
            the chances of rekindling that special spark.
          </li>
          <li>
            Anonymous Posting: Users can post about their missed connection
            without revealing personal information upfront, preserving a sense
            of mystery and encouraging genuine connections.
          </li>
          <li>
            Interactive Messaging: Once a connection is established, Winkd
            provides a secure and private messaging platform, allowing users to
            communicate and plan their next encounter.
          </li>
          <li>
            User-Friendly Interface: Winkd's sleek and user-friendly interface
            makes it easy for anyone to navigate the app, whether you're a tech
            enthusiast or a hopeless romantic.
          </li>
          <li>
            Authentic Connections: Winkd focuses on fostering genuine
            connections based on real-life encounters, helping users break free
            from the constraints of traditional online dating.
          </li>
        </ul>
        <p>
          "Winkd" is not just an app; it's a catalyst for real-life romantic
          comedies and authentic relationships. Join the community of
          individuals who believe in the magic of missed connections and turn
          those chance encounters into lifelong stories of love and connection.
          Download Winkd today and let serendipity guide you to your next
          meaningful connection.
        </p>
      </div>
    </div>
  );
};

export default About;
