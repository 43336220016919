import "./Phone.scss";

export const Phone = () => {
  return (
    <div className="demo__phone">
      <div className="demo__screen-wrapper">
        <div className="demo__screen">
          <div className="demo__card demo__card demo__card--notched">
            <div className="demo__card-backside"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
