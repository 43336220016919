import React from "react";

import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { guidelines } from "./constants";
import "./guidelines.css";

const Guidelines: React.FC = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  return (
    <div className="Guidelines">
      <BackButton onClick={goBack} />
      <div className="Guidelines-header">
        <h2>User Guidelines</h2>
      </div>
      {guidelines.map((guideline, index) => (
        <div key={index}>
          <h3>{guideline.title}</h3>
          <ul>
            {guideline.points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Guidelines;
