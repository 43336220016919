import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../App.css";
import { Phone } from "../../Phone/Phone";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Winkd is your go-to mobile app for rekindling missed connections and turning fleeting encounters into lasting connections. Inspired by the classic charm of Craigslist's Missed Connections and the nostalgic allure of newspaper personal ads, Winkd brings a modern twist to serendipitous encounters."
        />
        <meta property="og:title" content="Winkd - Missed Connections" />
        <meta property="og:description" content="Missed Connections New York" />
        <meta property="og:site_name" content="Winkd - Missed Connections" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="App">
        <div className="split left">
          <div className="centered">
            <div className="main">
              <div className="App-header">
                <h2>winkd</h2>
                <h3>everyone wants a meet-cute</h3>
              </div>
            </div>
            <div className="threefourth">
              <a href="https://apps.apple.com/us/app/winkd-missed-connections/id6472688973">
                <img
                  src={
                    require("../../assets/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg")
                      .default
                  }
                  alt="Download our missed connections app on the App Store"
                />
              </a>
            </div>
            <div className="terms">
              <Link to="/missed-connections">
                <h5 className="link">About</h5>
              </Link>
              <Link to="/privacy-policy">
                <h5 className="link">Privacy Policy</h5>
              </Link>
              <Link to="/terms-and-conditions">
                <h5 className="link">Terms & Conditions</h5>
              </Link>
              <Link to="/user-guidelines">
                <h5 className="link">User Guidelines</h5>
              </Link>
            </div>
          </div>
        </div>
        <div className="split right">
          <div className="centered">
            <Phone />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

// FILEPATH: /Users/antstu/Developer/winkd-web/src/pages/Home/index.tsx
// import { css } from "@emotion/react";

// const styles = css`
//   /* Common styles for both mobile and desktop */
//   .App {
//     display: flex;
//     flex-direction: row;
//     height: 100vh;
//   }

//   .split {
//     flex: 1;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .centered {
//     text-align: center;
//   }

//   .App-header {
//     margin-bottom: 2rem;
//   }

//   .terms {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 2rem;
//   }

//   .link {
//     margin-bottom: 1rem;
//   }

//   /* Mobile styles */
//   @media (max-width: 768px) {
//     .split {
//       flex-direction: column;
//     }

//     .threefourth {
//       margin-top: 2rem;
//     }
//   }
// `;

// export { styles };
